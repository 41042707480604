const languages: string[] = ["de", "en"];

const translations: { [key: string]: { [key: string]: [string, string] } } = {
  errors: {
    isRequired: ["Ist ein pflicht Feld!", "Is mandatroy!"],
    notSet: ["Ist ein pflicht Feld!", "Is mandatroy!"],
    notEmail: ["Ist keine E-mail!", "Is not an email!"],
    notExisting: [
      "Existiert nicht oder Anmeldedaten sind falsch!",
      "Does not exist or credentials are wrong!",
    ],
  },
  login: {
    signIn: ["Anmeldung", "Sign In"],
    email: ["E-Mail", "Email"],
    password: ["Passwort", "Password"],
    login: ["Anmelden", "Login"],
    createAccount: ["Jetzt registrieren?", "Create account?"],
  },
  register: {
    register: ["Registrieren", "Register"],
    firstname: ["Vorname", "Firstname"],
    lastname: ["Nachname", "Lastname"],
    email: ["E-Mail", "Email"],
    password: ["Passwort", "Password"],
    passwordRepeat: ["Passwort wiederholen", "Repeat password"],
    agbTxt: [
      "Sie sind mit unseren AGBs einverstanden.",
      "You are accepting our control of general terms and conditions.",
    ],
    agbTxtII: [
      "Bitte akzeptieren Sie unsere AGBs.",
      "Please accept our control of general terms and conditions.",
    ],
    dsgvoTxt: [
      "Sie sind mit unserer Datenschutzvereinbarung einverstanden.",
      "You are accepting our DSGVO.",
    ],
    dsgvoTxtII: [
      "Bitte akzeptieren Sie unsere DSGVO.",
      "Please accept our DSGVO.",
    ],
    cancel: ["Abbrechen", "Cancel"],
  },
};

export default function (language: string, group: string) {
  const lang = languages.findIndex((lang) => language === lang);
  const tr = translations[group];
  return (key: string) => {
    if (typeof tr[key] !== "object")
      return "UNDEFINED_TRANSLATION_KEY: " + group + "::" + key;
    if (typeof lang !== "number" || lang === -1)
      return "UNDEFINED_LANGUAGE: " + language;

    if (typeof tr[key][lang] !== "string")
      return "UNDEFINED_TRANSLATION: " + group + "::" + key;

    return tr[key][lang];
  };
}
