import React, { useState, useEffect } from "react";
import { buildClasses, ITheme, IDefinitions } from "@nodeme/jss-react";
import Login from "./components/Login";
import Registration from "./components/Registration";
import Challenge from "./components/Challenge";
import Refresh from "./components/Refresh";
import IUser from "@webcollector/client-sdk/lib/Interfaces/IUser";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Logout from "./components/Logout";

const states = ["LOGIN", "REGISTER", "RESET_PASSWORD", "REFRESH"];
const [LOGIN, REGISTER, , REFRESH] = states;

const styles = (theme: ITheme) => (definition: IDefinitions) => ({
  app: { backgroundColor: theme.palette.get("background"), height: "100%" },
});

let listenerActive = false;
const containerRef = React.createRef<HTMLDivElement>();
const sendSizes = () => {
  const sizes = containerRef.current?.getBoundingClientRect();
  if (sizes) window.parent.postMessage({ type: "resize", payload: sizes }, "*");
};

function App() {
  const classes = buildClasses(styles);
  const [state, setState] = useState<string>(REFRESH);
  const [user, setUser] = useState<IUser | null>(null);
  const [challenge, setChallenge] =
    useState<{
      id: string;
      email: string;
      challenges: string[];
    } | null>(null);

  useEffect(() => {
    sendSizes();
    if (!listenerActive) {
      listenerActive = true;
      window.addEventListener("resize", sendSizes);
    }
    return () => {
      window.removeEventListener("resize", sendSizes);
      listenerActive = false;
    };
  });

  return (
    <div className={classes.app}>
      <div ref={containerRef}>
        <Router>
          <Switch>
            <Route path="/logout" exact>
              <Logout />
            </Route>
            <Route path="/:lang" exact>
              {state === LOGIN && !challenge && (
                <Login
                  setUser={setUser}
                  states={states}
                  setState={setState}
                  setChallenge={setChallenge}
                />
              )}
              {state === REGISTER && !challenge && (
                <Registration
                  states={states}
                  setState={setState}
                  setChallenge={setChallenge}
                />
              )}
              {challenge !== null && (
                <Challenge
                  id={challenge.id}
                  email={challenge.email}
                  challenges={challenge.challenges}
                  cancel={() => setChallenge(null)}
                  states={states}
                  setState={setState}
                  setUser={setUser}
                />
              )}
              {state === REFRESH && (
                <Refresh
                  states={states}
                  setState={setState}
                  user={user}
                  setUser={setUser}
                />
              )}
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
