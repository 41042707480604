import SDK from "@webcollector/client-sdk";

export interface IState {
  values: {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    password2: string;
    dsgvo: boolean;
    agb: boolean;
  };
  errors: {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    password2: string;
    dsgvo: boolean;
    agb: boolean;
  };
  pending: boolean;
  unknownError: boolean;
}

export async function register(
  state: IState,
  setState: (state: IState) => void
): Promise<{ id: string; challenges: string[] } | null> {
  const newState = {
    ...state,
    errors: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      password2: "",
      dsgvo: false,
      agb: false,
    },
  };
  let hasError = false;
  //lazy check so you dont hit api with incomplete requests
  if (!state.values.firstname) {
    newState.errors.firstname = "notSet";
    hasError = true;
  }
  if (!state.values.lastname) {
    newState.errors.lastname = "notSet";
    hasError = true;
  }
  if (!state.values.email) {
    newState.errors.email = "notSet";
    hasError = true;
  }
  if (!state.values.password) {
    newState.errors.password = "notSet";
    hasError = true;
  }
  if (!state.values.password2) {
    newState.errors.password2 = "notSet";
    hasError = true;
  }
  if (state.values.password !== state.values.password2) {
    newState.errors.password2 = "notEqual";
    hasError = true;
  }
  if (!state.values.dsgvo) {
    newState.errors.dsgvo = true;
    hasError = true;
  }
  if (!state.values.agb) {
    newState.errors.agb = true;
    hasError = true;
  }
  if (state.values.password !== state.values.password2) {
    newState.errors.password2 = "notEqual";
    hasError = true;
  }
  if (hasError) {
    setState(newState);
    return null;
  }
  //set pending
  newState.pending = true;
  setState(newState);
  //make request
  try {
    const result = await SDK.register({
      firstname: state.values.firstname,
      lastname: state.values.lastname,
      email: state.values.email,
      password: state.values.password,
    });
    newState.pending = false;
    setState({ ...newState });
    return result;
  } catch (error) {
    newState.pending = false;
    if (error.name === "ValidationError") {
      const errors = { ...newState.errors, ...(error.errors || {}) };
      setState({ ...newState, errors });
    } else {
      newState.unknownError = true;
      setState({ ...newState });
    }
  }

  return null;
}
