import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import { faExclamationTriangle } from "@fortawesome/pro-duotone-svg-icons/faExclamationTriangle";
import { buildClasses, ITheme, IDefinitions } from "@nodeme/jss-react";

const styles = (theme: ITheme) => (definition: IDefinitions) => ({
  overlay: {
    left: 0,
    top: 0,
    zIndex: "3",
    backgroundColor: "rgba(255, 255, 255, 0.96)",
    position: "absolute",
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "4rem",
    marginTop: "100px",
  },
  error: {
    marginTop: "100px",
  },
  errorIcon: {
    color: theme.palette.get("danger"),
    fontSize: "4rem",
    marginBottom: "16px",
  },
});

const Pending: React.FC<{
  state: { pending: boolean; unknownError: boolean };
}> = (props) => {
  const classes = buildClasses(styles);

  return (
    <div
      style={{
        display:
          props.state.pending || props.state.unknownError ? "block" : "none",
      }}
      className={classes.overlay}
    >
      {props.state.pending && (
        <div className={classes.pending}>
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </div>
      )}
      {props.state.unknownError && (
        <div className={classes.error}>
          <FontAwesomeIcon
            className={classes.errorIcon}
            icon={faExclamationTriangle}
          />
          <br />
          Ein unbekannter Fehler ist aufgetreten.
        </div>
      )}
    </div>
  );
};

export default Pending;
