"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isUser(payload) {
    var testUser = payload;
    if (!testUser)
        return false;
    if (!Array.isArray(testUser.challenges))
        return false;
    if (!Array.isArray(testUser.scopes))
        return false;
    return (typeof testUser.id === "string" &&
        typeof testUser.usagePlan === "string" &&
        (testUser.firstname === null || typeof testUser.firstname === "string") &&
        (testUser.lastname === null || typeof testUser.lastname === "string") &&
        (testUser.email === null || typeof testUser.email === "string") &&
        !testUser.challenges.find(function (challenge) { return typeof challenge !== "string"; }) &&
        !testUser.scopes.find(function (scope) { return typeof scope !== "string"; }));
}
exports.isUser = isUser;
