import React, { useState } from "react";
import { Grid } from "@nodeme/grid-react";
import { Form } from "@nodeme/forms-react";
import Button from "@nodeme/forms-react/lib/Components/Button";
import Text from "@nodeme/forms-react/lib/Components/Text";
import Checkbox from "@nodeme/forms-react/lib/Components/Checkbox";
import { buildClasses, ITheme, IDefinitions } from "@nodeme/jss-react";
import { IState, register } from "../containers/Registration";
import StateHandler from "./StateHandler";
import { RouteComponentProps, withRouter } from "react-router-dom";
import i18nCreate from "../containers/i18n";

const styles = (theme: ITheme) => (definition: IDefinitions) => ({
  app: { backgroundColor: theme.palette.get("background"), height: "100%" },
  block: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    height: "100%",
  },
  headline: {
    fontWeight: 400,
    fontFamily: '"Noto Sans",sans-serif',
    margin: 0,
    padding: 0,
  },
  seperator: {
    height: "1px",
    backgroundColor: theme.palette.get("default"),
  },
  register: {
    textAlign: "right",
  },
  button: {
    border: "0px",
    backgroundColor: "#fff",
    outline: "none",
    cursor: "pointer",
    color: theme.palette.get("primary"),
  },
  errorText: {
    color: theme.palette.get("danger"),
  },
});

export interface IProps extends RouteComponentProps<{ lang: string }> {
  states: string[];
  setState: (state: string) => void;
  setChallenge: (challenge: {
    id: string;
    email: string;
    challenges: string[];
  }) => void;
}

const Registration: React.FC<IProps> = (props) => {
  const classes = buildClasses(styles);
  const [form, setForm] = useState<IState>({
    values: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      password2: "",
      dsgvo: false,
      agb: false,
    },
    errors: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      password2: "",
      dsgvo: false,
      agb: false,
    },
    pending: false,
    unknownError: false,
  });
  const [LOGIN] = props.states;
  const { setState } = props;

  const i18n = i18nCreate(props.match.params.lang, "register");
  const i18nErrors = i18nCreate(props.match.params.lang, "errors");

  return (
    <div className={classes.block}>
      <StateHandler state={form} />
      <Grid spacing={16} vertical>
        <Grid item>
          <h2 className={classes.headline}>{i18n("register")}</h2>
        </Grid>
        <Form
          spacing={8}
          root={{ left: 0, right: 0, top: 0, bottom: 0 }}
          onSubmit={async (e) => {
            e.preventDefault();
            const result = await register(form, setForm);
            if (result?.challenges && result.challenges.length > 0)
              props.setChallenge({
                id: result.id,
                email: form.values.email,
                challenges: result.challenges,
              });
          }}
        >
          <Text
            item
            inline
            label={i18n("firstname")}
            value={form.values.firstname}
            error={form.errors.firstname && i18nErrors(form.errors.firstname)}
            onChange={(value) => {
              const values = { ...form.values, firstname: value || "" };
              setForm({ ...form, values });
            }}
          />
          <Text
            item
            inline
            label={i18n("lastname")}
            value={form.values.lastname}
            error={form.errors.lastname && i18nErrors(form.errors.lastname)}
            onChange={(value) => {
              const values = { ...form.values, lastname: value || "" };
              setForm({ ...form, values });
            }}
          />
          <Text
            item
            inline
            label={i18n("email")}
            value={form.values.email}
            error={form.errors.email && i18nErrors(form.errors.email)}
            onChange={(value) => {
              const values = { ...form.values, email: value || "" };
              setForm({ ...form, values });
            }}
          />
          <Text
            item
            inline
            label={i18n("password")}
            type="password"
            value={form.values.password}
            error={form.errors.password && i18nErrors(form.errors.password)}
            onChange={(value) => {
              const values = { ...form.values, password: value || "" };
              setForm({ ...form, values });
            }}
          />
          <Text
            item
            inline
            label={i18n("passwordRepeat")}
            type="password"
            value={form.values.password2}
            error={form.errors.password2 && i18nErrors(form.errors.password2)}
            onChange={(value) => {
              const values = { ...form.values, password2: value || "" };
              setForm({ ...form, values });
            }}
          />
          <Checkbox
            item
            label={i18n("agbTxt")}
            value={form.values.agb}
            error={form.errors.agb}
            onChange={(value) => {
              const values = { ...form.values, agb: value };
              setForm({ ...form, values });
            }}
          />
          {form.errors.dsgvo && (
            <p className={classes.errorText}>{i18n("agbTxtII")}</p>
          )}
          <Checkbox
            item
            label={i18n("dsgvoTxt")}
            value={form.values.dsgvo}
            error={form.errors.dsgvo}
            onChange={(value) => {
              const values = { ...form.values, dsgvo: value };
              setForm({ ...form, values });
            }}
          />
          {form.errors.dsgvo && (
            <p className={classes.errorText}>{i18n("dsgvoTxtII")}</p>
          )}
          <Grid
            spacing={8}
            justify="flex-end"
            alignItems="flex-end"
            root={{ left: 0, right: 0, bottom: 0 }}
          >
            <Button
              item
              xs={5}
              sm={"150px"}
              onClick={(e) => {
                e.preventDefault();
                setState(LOGIN);
              }}
            >
              {i18n("cancel")}
            </Button>
            <Button item xs={5} sm={"150px"} success>
              {i18n("register")}
            </Button>
          </Grid>
          <div />
          <div />
        </Form>
      </Grid>
    </div>
  );
};

export default withRouter(Registration);
