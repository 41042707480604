import React from "react";
import SDK from "@webcollector/client-sdk";
import IUser, { isUser } from "@webcollector/client-sdk/lib/Interfaces/IUser";

interface IProps {
  states: string[];
  setState: (state: string) => void;
  user: IUser | null;
  setUser: (user: IUser | null) => void;
}

export default class Refresh extends React.Component<IProps> {
  timer: any = null;
  constructor(props: IProps) {
    super(props);
    this.refresh = this.refresh.bind(this);
  }
  async componentDidMount() {
    this.refresh();
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  async refresh() {
    const { setUser, setState, states } = this.props;
    const [LOGIN] = states;

    const result = await SDK.whoAmI();

    if (result !== null && isUser(result)) {
      const refreshIn =
        (result.exp || 0) - Math.floor(Date.now() / 1000) - 3600 * 0.2;
      this.timer = setTimeout(this.refresh, refreshIn * 1000);
    }

    if (result === null || isUser(result)) {
      setUser(result);
      window.parent.postMessage({ type: "userChange", payload: result }, "*");
    }

    if (result === null) setState(LOGIN);
  }
  render() {
    return <div />;
  }
}
