import SDK from "@webcollector/client-sdk";
import IUser, { isUser } from "@webcollector/client-sdk/lib/Interfaces/IUser";

export interface IState {
  challenges: string[];
  errors: { [key: string]: string };
  unknownError: boolean;
  pending: boolean;
}

export async function challenge(
  id: string,
  email: string,
  state: IState,
  setState: (state: IState) => void
): Promise<IUser | null> {
  const newState = { ...state, unknownError: false, errors: {} };
  newState.pending = true;
  setState(newState);

  try {
    const result = await SDK.challenge({
      id,
      email,
      challenges: state.challenges,
    });
    newState.pending = false;
    setState({ ...newState });
    return isUser(result) ? result : null;
  } catch (error) {
    newState.pending = false;
    if (error.name === "ValidationError")
      newState.errors = { ...newState.errors, ...error.errors };
    else newState.unknownError = true;
    setState({ ...newState });
    return null;
  }
}
