import React, { useState } from "react";
import { Form } from "@nodeme/forms-react";
import Button from "@nodeme/forms-react/lib/Components/Button";
import { buildClasses, ITheme, IDefinitions } from "@nodeme/jss-react";
import { Grid } from "@nodeme/grid-react";
import StateHandler from "./StateHandler";
import VerifyEmail from "./Challenges/VerifyEmail";
import { challenge as doChallenge, IState } from "../containers/Challenge";
import IUser, { isUser } from "@webcollector/client-sdk/lib/Interfaces/IUser";

const styles = (theme: ITheme) => (definition: IDefinitions) => ({
  block: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    height: "100%",
    padding: "16px",
  },
});

const Challenge: React.FC<{
  id: string;
  email: string;
  challenges: string[];
  cancel: () => void;
  setUser: (user: IUser | null) => void;
  states: string[];
  setState: (state: string) => void;
}> = (props) => {
  const classes = buildClasses(styles);
  const [form, setForm] = useState<IState>({
    challenges: props.challenges,
    errors: {},
    unknownError: false,
    pending: false,
  });

  const [, , , REFRESH] = props.states;

  return (
    <div className={classes.block}>
      <StateHandler state={form} />
      {form.challenges.map((challengeWithValue: string, index: number) => {
        const [challenge, ...values] = challengeWithValue.split(":");
        const value = values.join(":");
        const setValue = (toSet: string) => {
          const newForm = { ...form };
          newForm.challenges[index] = challenge + ":" + toSet;
          setForm(newForm);
        };
        switch (challenge) {
          case "verify_email":
            return (
              <VerifyEmail
                key="verify_email"
                value={value}
                error={form.errors.verify_email || ""}
                setValue={setValue}
              />
            );
          default:
            return "error: no interface for challenge: " + challenge + ".";
        }
      })}
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          const result = await doChallenge(
            props.id,
            props.email,
            form,
            setForm
          );
          if (result === null) return;
          if (isUser(result)) {
            props.setUser(result);
            props.setState(REFRESH);
            props.cancel();
          }
        }}
      >
        <Grid
          spacing={16}
          justify="flex-end"
          alignItems="flex-end"
          root={{ left: 0, right: 0, top: 0, bottom: 0 }}
        >
          <Button
            item
            xs={5}
            sm={"150px"}
            onClick={(e) => {
              e.preventDefault();
              props.cancel();
            }}
          >
            Cancel
          </Button>
          <Button item xs={5} sm={"150px"} primary>
            Send
          </Button>
        </Grid>
      </Form>
    </div>
  );
};

export default Challenge;
