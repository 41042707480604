"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isChallenge(payload) {
    var testChallenge = payload;
    if (!testChallenge)
        return false;
    if (!Array.isArray(testChallenge.challenges))
        return false;
    return (typeof testChallenge.id === "string" &&
        !testChallenge.challenges.find(function (challenge) { return typeof challenge !== "string"; }));
}
exports.isChallenge = isChallenge;
