import React, { useState } from "react";
import { Grid } from "@nodeme/grid-react";
import { Form, Text } from "@nodeme/forms-react";
import Button from "@nodeme/forms-react/lib/Components/Button";
import { buildClasses, ITheme, IDefinitions } from "@nodeme/jss-react";
import StateHandler from "./StateHandler";
import { login, IState } from "../containers/Login";
import IUser, { isUser } from "@webcollector/client-sdk/lib/Interfaces/IUser";
import i18nCreate from "../containers/i18n";
import { RouteComponentProps, withRouter } from "react-router-dom";

const styles = (theme: ITheme) => (definition: IDefinitions) => ({
  app: { backgroundColor: theme.palette.get("background"), height: "100%" },
  block: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    height: "100%",
  },
  headline: {
    fontWeight: 400,
    fontFamily: '"Noto Sans",sans-serif',
    margin: 0,
    padding: 0,
  },
  seperator: {
    height: "1px",
    backgroundColor: theme.palette.get("default"),
  },
  register: {
    textAlign: "right",
  },
  button: {
    border: "0px",
    backgroundColor: "#fff",
    outline: "none",
    cursor: "pointer",
    color: theme.palette.get("primary"),
  },
});

export interface IProps extends RouteComponentProps<{ lang: string }> {
  states: string[];
  setState: (state: string) => void;
  setChallenge: (challenge: {
    id: string;
    email: string;
    challenges: string[];
  }) => void;
  setUser: (user: IUser) => void;
}

const Login: React.FC<IProps> = (props) => {
  const classes = buildClasses(styles);
  const [form, setForm] = useState<IState>({
    values: { email: "", password: "" },
    errors: { email: "", password: "" },
    pending: false,
    unknownError: false,
  });
  const [, REGISTER, , REFRESH] = props.states;
  const { setState } = props;

  const i18n = i18nCreate(props.match.params.lang, "login");
  const i18nErrors = i18nCreate(props.match.params.lang, "errors");
  return (
    <div className={classes.block}>
      <StateHandler state={form} />
      <Grid spacing={16} vertical>
        <Grid item>
          <h2 className={classes.headline}>{i18n("signIn")}</h2>
        </Grid>
        <Form
          spacing={8}
          root={{ left: 0, right: 0, top: 0, bottom: 0 }}
          onSubmit={async (e) => {
            e.preventDefault();
            const result = await login(form, setForm);
            if (result === null) return;
            if (result?.challenges && result.challenges.length > 0)
              return props.setChallenge({
                id: result.id,
                email: form.values.email,
                challenges: result.challenges,
              });
            if (isUser(result)) {
              props.setUser(result);
              props.setState(REFRESH);
            }
          }}
        >
          <Text
            item
            inline
            label={i18n("email")}
            value={form.values.email}
            error={form.errors.email && i18nErrors(form.errors.email)}
            onChange={(value) => {
              const values = { ...form.values, email: value || "" };
              setForm({ ...form, values });
            }}
          />
          <Text
            item
            inline
            label={i18n("password")}
            type="password"
            value={form.values.password}
            onChange={(value) => {
              const values = { ...form.values, password: value || "" };
              setForm({ ...form, values });
            }}
          />
          <Grid
            spacing={8}
            justify="flex-end"
            alignItems="flex-end"
            root={{ top: 8, left: 0, right: 0, bottom: 0 }}
          >
            <Button item xs="150px" primary>
              {i18n("login")}
            </Button>
          </Grid>
        </Form>
        <Grid item>
          <div className={classes.seperator} />
        </Grid>
        <Grid xs={5} flow="left">
          {/*           <button
            className={classes.button}
            onClick={(e) => {
              e.preventDefault();
              setState(RESET_PASSWORD);
            }}
          >
            Reset password?
          </button> */}
        </Grid>
        <Grid xs={5}>
          <div className={classes.register}>
            <button
              className={classes.button}
              onClick={(e) => {
                e.preventDefault();
                setState(REGISTER);
              }}
            >
              {i18n("createAccount")}
            </button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Login);
