import React from "react";
import { buildClasses, ITheme, IDefinitions } from "@nodeme/jss-react";
import { Form } from "@nodeme/forms-react";
import Text from "@nodeme/forms-react/lib/Components/Text";

const styles = (theme: ITheme) => (definition: IDefinitions) => ({
  headline: {
    margin: "0px",
  },
  info: {
    fontSize: "0.8rem",
    fontStyle: "italic",
    color: theme.palette.get("grey"),
    margin: "16px 0px 0px 0px",
  },
});

const VerifyEmail: React.FC<{
  value: string;
  error: string;
  setValue: (value: string) => void;
}> = (props) => {
  const classes = buildClasses(styles);

  return (
    <div>
      <h2 className={classes.headline}>Verify Email</h2>
      <p className={classes.info}>
        We send you an email for verification. Please enter the code from the
        email here.
      </p>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        spacing={16}
        root={{ left: 0, right: 0 }}
      >
        <Text
          item
          inline
          label="Code"
          value={props.value}
          error={props.error}
          onChange={(value) => props.setValue(value || "")}
        />
      </Form>
    </div>
  );
};

export default VerifyEmail;
