import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import NodeMeJss from "@nodeme/jss-react";
import SDK from "@webcollector/client-sdk";

SDK.init({
  apiKeys: {
    artist: "ofvKKgYwsa8gu68IUZ4rn1agCfuz9BMSaTeTjJrs",
    auth: "yXEoSjFcw88fwlKSxJMUZ4uHa557RW2A5loDbWju",
    art: "EIAIZ2B8u17SPVNKILUvH7tu0raENohy9zz2WqH8",
  },
  url: "artceptional.com",
  cookies: "auto",
});

//parse query strings
const queryStrings: { [key: string]: string } = {};
(window.location.search.substr(1) || "").split("&").forEach((query: string) => {
  const [key, value] = query.split("=");
  queryStrings[key] = value;
});
//init jss
NodeMeJss.init({
  palette: {
    primary: "#246cb5",
    secondary: "#2c689c",
    tertiary: "#333333",
    danger: "#b10711",
    success: "#559554",
    warning: "#e8db05",
    default: "#d9d9d9",
    body: "#ececec",
    grey: "#7d7d7d",
    lightGrey: "#ececec",
    background: "#fff",
    formBackground: "rgba(224, 224, 224, 0.6)",
    formBackgroundActive: "rgba(224, 224, 224, 1)",
  },
});
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
