"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var TypeGuardError = /** @class */ (function (_super) {
    __extends(TypeGuardError, _super);
    function TypeGuardError(endpoint, type) {
        var _this = _super.call(this, "Endpoint (\"" + endpoint + "\") did not return expected type \"" + type + "\".") || this;
        _this.name = "TypeGuardError";
        return _this;
    }
    return TypeGuardError;
}(Error));
exports.default = TypeGuardError;
