import SDK from "@webcollector/client-sdk";
import IUser from "@webcollector/client-sdk/lib/Interfaces/IUser";

export interface IState {
  values: {
    email: string;
    password: string;
  };
  errors: {
    email: string;
    password: string;
  };
  pending: boolean;
  unknownError: boolean;
}

export async function login(
  state: IState,
  setState: (state: IState) => void
): Promise<IUser | { id: string; challenges: string[] } | null> {
  const newState = { ...state };
  //set pending
  newState.pending = true;
  setState(newState);
  //make request
  try {
    const result = await SDK.login(
      newState.values.email,
      newState.values.password
    );
    newState.pending = false;
    newState.unknownError = false;
    setState({ ...newState });
    return result;
  } catch (error) {
    newState.pending = false;
    if (error.name === "ValidationError" || error.name === "NotFoundError") {
      const errors = { ...newState.errors, ...(error.errors || {}) };
      setState({ ...newState, errors });
    } else if (error.name === "ChallengeError") {
      const id = error.message.split("=")[1];
      const { challenges } = error.errors;
      setState({ ...newState });
      return { id, challenges };
    } else {
      newState.unknownError = true;
      setState({ ...newState });
    }
  }

  return null;
}
