import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import SDK from "@webcollector/client-sdk";

interface IProps extends RouteComponentProps {}

class Logout extends React.Component<IProps> {
  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);

    await SDK.logout();
    const redirect = query.get("to");
    if (typeof redirect === "string") window.location.href = redirect;
  }
  render() {
    return <div>Logout...</div>;
  }
}

export default withRouter(Logout);
